
import { defineComponent, ref, onMounted } from 'vue'
import * as THREE from 'three'
import Stats from 'stats.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import * as dat from 'dat.gui'

import galaxyVertexShader from '@/shaders/galaxy/vertex.glsl'
import galaxyFrafmentShader from '@/shaders/galaxy/fragment.glsl'

export default defineComponent({
  setup () {
    // get container
    const container = ref()
    // core variable
    let scene: THREE.Scene
    let camera: THREE.PerspectiveCamera
    let renderer: THREE.WebGLRenderer

    let geometry: THREE.BufferGeometry
    let material: THREE.ShaderMaterial
    let model: THREE.Points

    let stats: Stats
    let controls: OrbitControls

    /**
     * @description : init dat.gui
     * @return       {*}
     */
    const parameter = {
      count: 50000,
      size: 0.005,
      radius: 5,
      branch: 3,
      spin: 1,
      randomness: 0.5,
      randomnessPowner: 5,
      innerColor: 0xff6030,
      outerColor: 0x1b3984
    }
    function initGui (): void {
      const datGui = new dat.GUI()

      datGui.add(parameter, 'count').min(100).max(100000).step(100).onFinishChange(() => {
        createParticles()
      })

      datGui.add(parameter, 'radius').min(1).max(10).step(1).onFinishChange(() => {
        createParticles()
      })

      datGui.add(parameter, 'branch').min(3).max(10).step(1).onFinishChange(() => {
        createParticles()
      })

      datGui.add(parameter, 'randomness').min(0).max(1).step(0.2).onFinishChange(() => {
        createParticles()
      })

      datGui.add(parameter, 'randomnessPowner').min(1).max(5).step(0.2).onFinishChange(() => {
        createParticles()
      })

      datGui.addColor(parameter, 'innerColor').onFinishChange(() => {
        createParticles()
      })

      datGui.addColor(parameter, 'outerColor').onFinishChange(() => {
        createParticles()
      })
    }

    /**
     * @description : init scene
     * @return       {*}
     */
    function initScene (): void {
      scene = new THREE.Scene()
    }
    /**
     * @description : init Light
     * @return       {*}
     */
    function initLight (): void {
      const ambientLight = new THREE.AmbientLight(0x444fff)
      scene.add(ambientLight)
    }
    /**
     * @description : init camera
     * @param        {*} container
     * @return       {*}
     */
    function initCamera (container: HTMLElement): void {
      const aspect = container.clientWidth / container.clientHeight
      camera = new THREE.PerspectiveCamera(45, aspect, 0.1, 2000)
      camera.position.set(0, 2, 6)
      scene.add(camera)
    }
    /**
     * @description : init renderer
     * @param        {*} container
     * @return       {*}
     */
    function initRenderer (container: HTMLElement): void {
      renderer = new THREE.WebGLRenderer({ antialias: true })
      renderer.setSize(container.clientWidth, container.clientHeight, false)
      renderer.setClearColor(0x000000)
      // renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

      container.appendChild(renderer.domElement)
    }
    /**
     * @description : init model
     * @return       {*}
     */
    function createParticles (): void{
      const positions = []
      const colors = []
      const scales = []
      const randomness = []
      const innerColor = new THREE.Color(parameter.innerColor)
      const outerColor = new THREE.Color(parameter.outerColor)
      for (let i = 0; i < parameter.count; i++) {
        const i3 = i * 3

        const radius = Math.random() * parameter.radius

        const branchAngle = (i % parameter.branch) / parameter.branch * Math.PI * 2

        positions[i3] = Math.cos(branchAngle) * radius
        positions[i3 + 1] = 0.0
        positions[i3 + 2] = Math.sin(branchAngle) * radius

        const randomX = Math.pow(Math.random(), parameter.randomnessPowner) * (Math.random() < 0.5 ? 1 : -1)
        const randomY = Math.pow(Math.random(), parameter.randomnessPowner) * (Math.random() < 0.5 ? 1 : -1)
        const randomZ = Math.pow(Math.random(), parameter.randomnessPowner) * (Math.random() < 0.5 ? 1 : -1)
        randomness[i3] = randomX
        randomness[i3 + 1] = randomY
        randomness[i3 + 2] = randomZ

        const mixedColor = innerColor.clone()
        mixedColor.lerp(outerColor, radius / parameter.radius)

        colors[i3] = mixedColor.r
        colors[i3 + 1] = mixedColor.g
        colors[i3 + 2] = mixedColor.b

        scales[i] = Math.random()
      }
      geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3))
      geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3))
      geometry.setAttribute('aScale', new THREE.Float32BufferAttribute(colors, 1))
      geometry.setAttribute('aRandomness', new THREE.Float32BufferAttribute(randomness, 3))
    }
    function initModel (): void {
      geometry = new THREE.BufferGeometry()

      material = new THREE.ShaderMaterial({
        vertexShader: galaxyVertexShader,
        fragmentShader: galaxyFrafmentShader,
        depthWrite: false,
        blending: THREE.AdditiveBlending,
        vertexColors: true,
        uniforms: {
          uSize: {
            value: 30.0 * renderer.getPixelRatio()
          },
          uTime: {
            value: 0.016
          }
        }
      })

      model = new THREE.Points(geometry, material)
      createParticles()

      scene.add(model)
    }

    function render (): void {
      renderer.render(scene, camera)
    }

    const clock = new THREE.Clock()
    function tick (): void {
      const elapsedTime = clock.getElapsedTime()
      material.uniforms.uTime.value = elapsedTime

      material.needsUpdate = true
      stats.update()
      controls.update()

      render()

      requestAnimationFrame(tick)
    }

    /**
     * @description : init stats
     * @param        {*} container
     * @return       {*}
     */
    function initStats (container: HTMLElement): void {
      stats = new Stats()
      container.appendChild(stats.dom)
    }

    /**
     * @description : init controls
     * @return       {*}
     */
    function initControls (): void {
      controls = new OrbitControls(camera, renderer.domElement)
      controls.autoRotate = false
    }

    /**
     * @description : window resize event
     * @return       {*}
     */
    function onWindowResize (): void {
      const containerElement = container.value as HTMLElement
      const aspect = containerElement.clientWidth / containerElement.clientHeight

      camera.aspect = aspect
      camera.updateProjectionMatrix()

      renderer.setSize(containerElement.clientWidth, containerElement.clientHeight, false)
    }

    onMounted(() => {
      const containerElement = container.value as HTMLElement

      initScene()
      initLight()
      initCamera(containerElement)
      initRenderer(containerElement)

      initModel()

      initStats(containerElement)
      initControls()
      initGui()

      tick()

      window.addEventListener('resize', onWindowResize, false)
    })
    return {
      container
    }
  }
})
