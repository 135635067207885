<template>
  <router-view/>
</template>

<style lang="scss">
* {
  box-sizing: border-box;
}
html, body {
  margin: 0;
  height: 100%;
  min-width: 1366px;
  overflow-y: hidden;
}
body {
  overflow-x: hidden;
  background-color: var(--theme-global-background);
}
html {
  word-break: normal;
  word-wrap: break-word;
}
pre {
  white-space: pre-wrap;
}
p {
  margin: 0;
}
#app {
  font-family: 'PingFangSC-Regular', 'PingFang SC', 'Microsoft YaHei', Helvetica, 'Avenir', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  min-width: 1200px;
  word-break: normal;
  word-wrap: break-word;
}
</style>
