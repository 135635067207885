
import { defineComponent } from 'vue'
import WEBGL from 'three/examples/jsm/capabilities/WebGL'
// import HelloWorld from '../components/HelloWorld.vue'
// import Line from '../components/Line/Line.vue'
// import LineLoop from '../components/Line/LineLoop.vue'
// import LineSegments from '../components/Line/LineSegments.vue'
// import RotateCube from '../components/Examples/RotateCube.vue'
// import GeometryDisplay from '../components/Geometry/Display.vue'
// import TrackballControls from '../components/Controls/TrackballControls/index.vue'
// import OrbitControls from '../components/Controls/OrbitControls/index.vue'
// import FirstPersonControls from '../components/Controls/FirstPersonControls/index.vue'
// import PointerLockControls from '../components/Controls/PointerLockControls/index.vue'
// import Stats from '../components/Plugins/Stats.vue'
// import Shadow from '../components/Shadow/index.vue'
// import DatGui from '../components/Plugins/DatGui.vue'
// import Fog from '../components/Fog/index.vue'
// import PointToBox from '../components/Geometry/PointToBox.vue'
// import MeshAttribute from '../components/Objects/Mesh/index.vue'
// import Light from '../components/Light/index.vue'
// import LightCanShadow from '../components/Light/CanShadow.vue'
// import LightNoShadow from '../components/Light/NoShadow.vue'
// import FlyControls from '../components/Controls/FlyControls/index.vue'
// import Lensflare from '../components/Light/Lensflare.vue'
// import MeshBasicMaterial from '@/components/Material/MeshBasicMaterial.vue'
// import MeshDepthMaterial from '@/components/Material/MeshDepthMaterial.vue'
// import MeshDistanceMaterial from '@/components/Material/MeshDistanceMaterial.vue'
// import MeshLambertMaterial from '@/components/Material/MeshLambertMaterial.vue'
// import MeshMatcapMaterial from '@/components/Material/MeshMatcapMaterial.vue'
// import MeshNormalMaterial from '@/components/Material/MeshNormalMaterial.vue'
// import MeshPhongMaterial from '@/components/Material/MeshPhongMaterial.vue'
// import MeshToonMaterial from '@/components/Material/MeshToonMaterial.vue'
// import MeshStandardMaterial from '@/components/Material/MeshStandardMaterial.vue'
// import MeshPhysicalMaterial from '@/components/Material/MeshPhysicalMaterial.vue'

// import Sprite from '@/components/Objects/Sprite.vue'
// import Points from '@/components/Objects/Points.vue'
// import Raycaster from '@/components/Raycaster/index.vue'

// import MeshGroup from '@/components/MeshGroup.vue'
// import GeometryMerge from '@/components/GeometryMerge.vue'

// import MtlWithObj from '@/components/MtlWithObj/index.vue'
// import STL from '@/components/STL/index.vue'
// import GLTF from '@/components/GLTF/index.vue'

// import STL from '@/components/STL/index.vue'

// import Tween from '@/components/Animate/Tween.vue'
// import Gsap from '@/components/Animate/Gsap.vue'
// import AnimationMixer from '@/components/Animate/AnimationMixer.vue'

// import TextureAttribute from '@/components/Texture/Attribute.vue'
// import PartialUpdate from '@/components/Texture/PartialUpdate.vue'

// import Csg from '@/components/Geometry/Csg.vue'
// import PointInBox from '@/components/PositionRelationship/PointInBox.vue'
// import Coordinate from '@/components/Coordinate/index.vue'

// import Velocity from '@/components/Velocity/index.vue'
// import PhysicsBase from '@/components/Cannon/Base.vue'

// import TunnelAnimation from '@/components/Demo/TunnelAnimation/index.vue'
// import HauntedHouse from '@/components/Demo/HauntedHouse/index.vue'
import GalaxyGenerator from '@/components/Demo/GalaxyGenerator/index.vue'
// import ScrollAnimateWebPage from '@/components/Demo/ScrollAnimateWebPage/index.vue'

// import RawShaderMaterial from '@/components/RawShaderMaterial/index.vue'
// import ShaderMaterial from '@/components/ShaderMaterial/index.vue'
// import RagingSea from '@/components/Demo/RagingSea/index.vue'

export default defineComponent({
  name: 'Home',
  components: {
    // HelloWorld,
    // Line,
    // LineLoop,
    // LineSegments,
    // RotateCube,
    // GeometryDisplay,
    // TrackballControls,
    // OrbitControls,
    // Stats,
    // Shadow,
    // DatGui,
    // Fog,
    // PointToBox,
    // MeshAttribute,
    // Light,
    // LightCanShadow
    // LightNoShadow,
    // FlyControls,
    // Lensflare,
    // MeshBasicMaterial,
    // MeshDepthMaterial,
    // MeshDistanceMaterial,
    // MeshLambertMaterial,
    // MeshMatcapMaterial,
    // MeshNormalMaterial,
    // MeshPhongMaterial,
    // MeshToonMaterial,
    // MeshStandardMaterial,
    // MeshPhysicalMaterial,
    // Sprite,
    // Points,
    // Raycaster,
    // MeshGroup,
    // GeometryMerge,
    // MtlWithObj,
    // GLTF,
    // STL,
    // Tween,
    // Gsap,
    // FirstPersonControls,
    // PointerLockControls,
    // TextureAttribute,
    // PartialUpdate,
    // Csg,
    // PointInBox,
    // Coordinate,
    // Velocity,
    // PhysicsBase,
    // AnimationMixer,
    // TunnelAnimation,
    // HauntedHouse,
    GalaxyGenerator
    // ScrollAnimateWebPage,
    // RawShaderMaterial,
    // ShaderMaterial,
    // RagingSea,
  },
  setup () {
    // 判断浏览器webgl支持
    if (WEBGL.isWebGLAvailable() === false) {
      document.body.appendChild(WEBGL.getWebGLErrorMessage())
    }
    return {}
  }
})
